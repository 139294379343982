.modal-pay-2 {
    // top: 61px;
    animation: fly .4s ease-in;
    overflow: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    z-index: 99999;

    
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  
    .modal-content {
      height: auto;
      min-height: 100%;
      background: transparent;
    }
  
    .modal-header {
      border-bottom: none;
      padding: 10px 15px;
      .close {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
        outline: none;
        border: none;
        opacity: 1;
      }
    }
  
    .modal-body {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px 20px 0px 0px;
      background: #212529;
      height: 50vh;
      overflow: auto;
    }

    .wrapper-pay-2{
        display: flex;
        justify-content: center;

        .section-content{
            width: 677px;
            // height: 1119px;
            background-color: #EEEEEE;
            // padding: 1em;
            border-radius: 5px;

            .content-header{
                height: 79px;
                background: linear-gradient(90deg, #00BAF3 23.5%, #75DFFF 100%);
                padding: 1em;
                text-align: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .content-header-title{
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 60px;
                    color: #ffffff;
                    font-weight: bold;
                }
                
            }
            
            .content-subs{
                height: 79px;
                padding: 1em;
                margin: 25px 30px 12px 30px;
                background-color: #ffffff;
                border-radius: 5px;

                .content-subs-title{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-subs-title-price{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                    float: right;
                }
            }

            .content-addon{
                height: 79px;
                padding: 1em;
                margin: 0px 30px 12px 30px;
                background-color: #ffffff;
                border-radius: 5px;

                .content-addon-title{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-addon-title-price{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                    float: right;
                }
            }

            .content-amount{
                height: 123px;
                padding: 1em;
                margin: 0px 30px 12px 30px;
                background-color: #ffffff;
                border-radius: 5px;       

                .content-amount-title{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-amount-title-price{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                    float: right;
                }               

            }

            .content-method{
                height: 129px;
                padding: 1em;
                margin: 0px 30px 12px 30px;
                background-color: #ffffff;
                border-radius: 5px;       

                .content-method-title{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-method-title-price{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                    float: right;
                }               
                .btn-payment{
                    width: 586px;
                    height: 60px;
                    border: 1px solid #AAAAAA;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    border-radius: 7px;
                    text-align: left;
                    margin-top: 11px;
                    cursor: pointer;
                }
            }

            .content-coupon{                 

                .input-promo{
                    height: 51px;
                    width: 617px;
                    padding: 1em;
                    margin: 0px 30px 12px 30px;
                    background-color: #EEEEEE;
                    border-radius: 5px; 
                    border: 1px solid #AAAAAA;
                    text-align: center;
                }

                .coupon-form {
                    margin: 0px 30px 12px 30px;
                    border-radius: 5px; 
                    input[type=text]{
                        height: 51px;
                        background-color: #EEEEEE;
                        border: 1px solid #AAAAAA;
                        text-align: center;
                    }
                }

                .capitalize{
                    margin: 0px 30px 12px 30px;
                    color: #212529;
                }

                .btn-send{
                    background-color: #00BAF3;
                    color: #ffffff;
                    border: none;
                    cursor: pointer;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .content-buy{
                height: 109px;
                padding: 1.5em;
                background-color: #ffffff;
                border-radius: 5px;       

                .content-buy-title{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-buy-title-price{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                }
                .content-buy-title-price{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 30px;
                    color: #333333;
                    float: right;
                }   
                .btn-buy{
                    width: 300px;
                    height: 52px;
                    background-color: #00BAF3;
                    color: #ffffff;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }            

            }
          }
    }

  }