.margin-tb{
    margin: 1em 0 1em;
}

.title-cast{
    font-weight: bold;
}

.text-cast{
    color: #00baf3;
    margin-bottom: 2em;
}

.badge-tag{
    background-color: #363942;
    height: 2em;
    padding: 0.5em;
    margin-bottom: 0.5em;
}



.banner-btn-sec{
    display: flex;
    align-items: baseline;
    .express-btn{
        width: 2.6em!important;
        height: 2.6em!important
    }
    .btn{
        height: 2.6em;
    }
    
}