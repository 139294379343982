.signin-btn {
    background-color: #00baf3;
    line-height: normal;
    padding: 7px 12px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.cast-item{
    width: 18vw;
    margin: 0 1em 0 1em;
    flex: 0 0 auto;
}

.menu-item.show{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 90vh;
    width: 80vw!important;
    align-items: left;
    overflow-y: scroll;
    
    
}

.suggestion-title{
    cursor: pointer;
}

.icon-user{
    width: 2vw;
}

#header{
    font-size: 16px;
    padding-left: 25px;
    padding-right: 15px;
    font-weight: bold;
}

.navbar-nav .desktop-nav{
    flex-wrap:wrap;
}

@media screen and (max-width: 1650px) {
    #header{
        font-size: 14px;
    }
}

@media screen and (max-width: 1240px) {
    #header{
        font-size: 12px;
    }
}

@media (min-width : 990px) and (max-width: 1199px){
    #header{
        font-size: 10px;
    }
}