.modal-pay-1 {
    // top: 61px;
    animation: fly .4s ease-in;
    overflow: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    z-index: 99999;

    
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  
    .modal-content {
      height: auto;
      min-height: 100%;
      background: transparent;
    }
  
    .modal-header {
      border-bottom: none;
      padding: 10px 15px;
      margin-top: 60px;
      .close {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
        outline: none;
        border: none;
        opacity: 1;
      }
    }
  
    .modal-body {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px 20px 0px 0px;
      // background: #212529;
      height: 50vh;
      overflow: auto;
    //   opacity: 0.7 !important;
      background-color:rgba(0, 0, 0, 0.8);
    }

    .wrapper-pay-1{
        padding-top: 117px;

        .section-content{
            width: 365px;
            height: 466px;
            background-color: #ffffff;
            // padding: 1em;
            border-radius: 5px;
            
            .content-header{
                height: 69px;
                padding: 1em;

                .content-header-title{
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 42px;
                    color: #333333;
                    text-transform: uppercase;
                }
            }

            .content-body{
                height: 112px;
                background: linear-gradient(90deg, #00BAF3 23.5%, #75DFFF 100%);
                padding: 0.8em;

                .content-body-title{
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 34px;
                }
                .content-body-title-price{
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 42px;
                }
            }

            .content-footer{
                padding: 1em;
                color: #333333;

                .content-footer-title{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
                }

                .logo{
                    padding-right: 1em;
                }

                .btn-gin{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 34px;
                    border-radius: 5px;
                    border: none;
                    background: #00BAF3;
                    color: #ffffff;
                    float: right;
                    margin-top: 44px;
                    cursor: pointer;
                }
            }
          }
    }

  }