.slider__container{
    // padding:0px!important;
}
.tag-item{
    
    border-style: solid;
    border-radius: 5px;
    border-color: #6c757d;
    color: #6c757d;
    border-width: 1px;
    width: 21%;
    padding: 1em;
    margin-bottom: 1.5em;
    font-weight: bold;
    text-align: center;
}

.tag-item:hover{
    background-color: #6c757d;
    color: white;
    cursor: pointer;
}

.tag-container{
    flex-wrap: wrap;
    justify-items: center;
    justify-content: space-between;
    
    flex-direction: columns;
    width: 100%;
    padding: 0 10% 55px 10%;
}

.title-margin-left{
    margin-left: 10%;
    color: grey;
}