.slider {
  width: 100%;
  height: auto;

  &:not(&--open) .item:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) .item:hover {
    transform: scale(1.5) !important;
  }

  &:not(&--open):hover .item {
    transform: translateX(-25%);
    left: 3.5vw;
  }

  &:not(&--open) .item:hover ~ .item {
    transform: translateX(25%);
  }
}

.slider__container {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
  scroll-behavior: smooth;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
}

.slider--compact {
  // justify-content: flex-end;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  & > *:not(:last-child) {
    margin-right: 20px;
  }
  
  & > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }
}
