.is-chrome-modal {
  animation: fly 0.4s ease-in;
  overflow: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;
  z-index: 9999999999999999;
  background: rgba(black, 0.7);


  .modal-content {
    width: 354px;
    height: auto;
    display: block;
    margin: auto;
  }

  .modal-body {
    color: #333333;

    .circle {
      width: 86px;
      height: 86px;
      background: #ffffff;
      border-radius: 100%;
      display: block;
      margin: auto;
      margin-top: -60px;
      fill: #fff;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      .icon-chrome {
        width: 47px;
        display: block;
        margin: auto;
        padding-top: 11px;
      }

      .icon-change {
        display: block;
        margin: auto;
        padding-top: 5px;
      }
    }

    .title {
      text-transform: uppercase;
      color: #333;
      font-size: 19px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin-top: 15px;
    }
    .sub-title {
      color: #333;
      font-size: 14.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 164.286% */
      margin-top: 15px;
    }

    .btn-close {
      width: 85px;
      height: 37px;
      background-color: #00baf3;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      display: block;
      margin: auto;
      margin-top: 30px;
    }
  }
}
