
.popup-wrapper{

  display: flex;
  justify-content: center;
  position: relative;
  .popup{
    
    align-items: center;
    position: absolute;
    top: -2.7em;
    transition: visibility 0s, opacity 0.5s linear;
  }

}

.show-share{
  display: flex;
  transition: visibility 0s, opacity 0.5s linear;
}

.hide-share{
  display: none;
}



