.comika-title{
    padding-bottom: 40px;
}
.cast-section{
    width: 349px;
    height: 100px;
    border:1px solid white;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 25px;
}
.cast-img{
    width: 64px;
    height: 64px;
    margin-right: 10px;
}