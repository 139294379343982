.content-detail {
  position: relative;
  height: 37vw;
  margin-top: 66px;
  margin-right: -30px;

  &__background,
  &__background__shadow,
  &__background__image,
  &__area {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &__background {
    left: 0;
    right: 0;

    &__shadow {
      left: 0;
      background: #000;
      width: 30%;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to right,#000,transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px;
      }
    }

    &__image {
      right: 0;
      width: 70%;
      height: 100%;
      background-position: center 10%;
      background-size: cover;
      z-index: 1;
    }
  }

  &__area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;

    &__container {
      padding: 30px 70px;
      color: wheat;
    }
  }

  &__title {
    font-size: 45px;
    color: #fff;
    font-weight: 700;
  }

  &__description {
    font-size: 18px;
    color: #999;
    margin-top: 20px;
    max-width: 500px;
  }

  &__close {
    color: #fff;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.video-control-bottom-trailer{
    position: absolute;
    bottom: -5px;
    right: 3vw;

  .control-icon {
    width: 35px;
    height: 35px;
    transition: .25s;
    transition-timing-function: ease-out;
    opacity: 0.3;
  }

  .control-sec {
    .control-icon:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }
}

@media (max-width: 400px) {
  .content__background__image_mobile {
    top: 330px !important;
    height: auto !important;
  }

  .content__desktop {
    display: none !important;
  }

  .content__mobile {
    display: block !important;
  }
}

@media (min-width: 401px) {
  .content__desktop {
    display: block !important;
  }

  .content__mobile {
    display: none !important;
  }
}

@media (min-width: 1053px) {
  .main-sec-content{
    margin-bottom: -53px !important;
  }
}


.overview-content{
    height: 70vh;
    margin-top: 5vh;
}